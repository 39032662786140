<template>
  <div class="container-fluid">
    <router-link v-if="is_super || is_admin_sb" to="/wizards/createPartners" class="nav-link">
      <font-awesome-icon icon="cubes" /> {{ $t('home.wizardpartnercreation') }}
    </router-link>

    <router-link v-if="is_super || is_admin_sb" to="/receipt" class="nav-link">
      <font-awesome-icon icon="receipt" /> {{ $t('app.checks') }}
    </router-link>

    <router-link v-if="is_super" to="/games" class="nav-link">
      <font-awesome-icon icon="dice" /> {{ $t('app.gameSetup') }}
    </router-link>

    <router-link v-if="is_super || is_admin_sb" to="/wizards/groupoperations" class="nav-link">
      <font-awesome-icon icon="object-group" /> {{ $t('home.groupOperationsWizard') }}
    </router-link>

    <router-link v-if="is_super || is_admin_sb" to="/currency/currenciesedit" class="nav-link">
      <font-awesome-icon icon="coins" /> {{ $t('home.currencyEdit') }}
    </router-link>

    <router-link v-if="is_super || is_admin_sb" to="/loaders/logotypes" class="nav-link">
      <font-awesome-icon icon="image" /> {{ $t('home.logotypes') }}
    </router-link>

    <router-link v-if="is_super" to="/table/tables" class="nav-link">
      <font-awesome-icon icon="tablet-alt" /> {{ $t('home.table') }}
    </router-link>

    <router-link v-if="is_super" to="/InvisibleBets" class="nav-link">
      <font-awesome-icon icon="mask" /> {{ $t('home.InvisibleBets') }}
    </router-link>

    <router-link v-if="isAuthorized" to="/PaymentConfirmation" class="nav-link">
      <font-awesome-icon icon="funnel-dollar" /> {{ $t('home.PaymentConfirmation') }}
    </router-link>

    <router-link v-if="is_super" to="/Bets/KoefEditor" class="nav-link">
      <font-awesome-icon icon="subscript" /> {{ $t('home.BetsKoefEditor') }}
    </router-link>

    <router-link v-if="is_super" to="/wizards/eventprocessormanual" class="nav-link">
      <font-awesome-icon icon="calculator" /> {{ $t('home.eventprocessormanual') }}
    </router-link>

    <router-link v-if="is_super || is_admin_sb" to="/wizards/systemloganalysis" class="nav-link">
      <font-awesome-icon icon="calculator" /> {{ $t('wizards.SystemLogAnalysis.SystemLogAnalysis') }}
    </router-link>
  </div>
</template>

<script setup>
import {inject} from "vue";

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')
const isAuthorized = inject('isAuthorized')
</script>
