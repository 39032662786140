export const bill = {
    namespaced: true,
    state: {
        selBills: [],
        selOneBillInfo: [],
        selBillsInfo: [],
        selBillsInfoAll: [],
        tblCnt: 0,
        typesData: {"work": {}, "demo": {}},
        collapseBills: [],
        isUpdBills: 0,
        showDelBill: false
    },
    actions: {
        addSelBill({ commit }, payload) {
            commit('addSelBill', payload);
        },
        delSelBill({ commit }, payload) {
            commit('delSelBill', payload);
        },
        clearSelBill({ commit }, payload) {
            commit('clearSelBill', payload);
        },
        clearBillsInfo({ commit }, payload) {
            commit('clearBillsInfo', payload);
        },
        selOneBillInfo({ commit }, payload) {
            commit('selOneBillInfo', payload);
        },
        selBillsInfo({ commit }, payload) {
            commit('selBillsInfo', payload);
        },
        selBillsInfoAll({ commit }, payload) {
            commit('selBillsInfoAll', payload);
        },
        tblCnt({ commit }, payload) {
            commit('tblCnt', payload);
        },
        typeCnt({ commit }, payload) {
            commit('typeCnt', payload);
        },
        addCollapseBills({ commit }, payload) {
            commit('addCollapseBills', payload);
        },
        delCollapseBills({ commit }, payload) {
            commit('delCollapseBills', payload);
        },
        clearCollapseBills({ commit }) {
            commit('clearCollapseBills');
        },
        setInnercheck({ commit }, payload) {
            commit('setInnercheck', payload);
        },
        clearBills({ commit }, payload) {
            commit('clearBills', payload);
        },
        updBills({ commit }, payload) {
            commit('updBills', payload);
        },
        setShowDelBill({ commit }, payload) {
            commit('setShowDelBill', payload);
        }
    },
    getters: {
        doneTodos (state) {
            return state.treeBills
        },
        getSelBills (state) {
            return state.selBills
        },
        getSelOneBillInfo (state) {
            return state.selOneBillInfo
        },
        getSelBillsInfo (state) {
            return state.selBillsInfo
        },
        getSelBillsInfoAll (state) {
            return state.selBillsInfoAll
        },
        getTblCnt (state) {
            return state.tblCnt
        },
        getCollapseBills (state) {
            return state.collapseBills
        }
    },
    mutations: {
        addSelBill (state, payload) {
            let myIndex;
            let curLogin;
            for(let key in payload) {
                curLogin=payload[key]
                myIndex = state.selBills.indexOf(curLogin)
                if (myIndex == -1) {
                    state.selBills.push(curLogin)
                }
            }
        },
        delSelBill (state, payload) {
            let myIndex;
            let curLogin;
            for(let key in payload) {
                curLogin=payload[key]
                myIndex = state.selBills.indexOf(curLogin)
                if (myIndex !== -1) {
                    state.selBills.splice(myIndex, 1);
                }
            }
        },
        clearSelBill (state, login) {
            state.selBills=[];

            if(login!==null) {
                state.selBills.push(login)
            }
        },
        clearBillsInfo (state) {
            state.selBills=[];
            state.collapseBills=[];
        },
        selOneBillInfo (state, info) {
            state.selOneBillInfo=info
        },
        selBillsInfo (state, info) {
            state.selBillsInfo=info
        },
        selBillsInfoAll (state, info) {
            state.selBillsInfoAll=info
        },
        selTblCnt (state, info) {
            state.selTblCnt=info
        },
        addCollapseBills (state, payload) {
            let myIndex;
            let curLogin;
            curLogin=payload
            myIndex = state.collapseBills.indexOf(curLogin)
            if (myIndex == -1) {
                state.collapseBills.push(curLogin)
            }
        },
        delCollapseBills (state, payload) {
            let myIndex;
            let curLogin;
            curLogin=payload
            myIndex = state.collapseBills.indexOf(curLogin)
            if (myIndex !== -1) {
                state.collapseBills.splice(myIndex, 1);
            }
        },
        clearCollapseBills (state) {
            state.collapseBills=[]
        },
        setInnercheck (state, payload) {
            let login=payload["login"]
            let check=payload["check"]
            let tbc=state.treeBills;
            for(let key in tbc[login]) {
                tbc[login][key].sel=check
            }
            state.treeBills=tbc
        },
        clearBills (state) {
            state.isUpdBills=0
        },
        updBills (state) {
            state.isUpdBills++
        },
        setShowDelBill (state) {
            state.showDelBill=state
        }
    }
};