import { createApp } from "vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import en from 'element-plus/es/locale/lang/en'
import es from 'element-plus/es/locale/lang/es'
import fr from 'element-plus/es/locale/lang/fr'
import ru from 'element-plus/es/locale/lang/ru'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Select2 from 'vue3-select2-component';
import Maska from 'maska'
import i18n from "./i18n";
import HighchartsVue from 'highcharts-vue'

//надо разобраться как подключить этот модуль. Вызывает варнинг.
//highcharts.js:13 Highcharts warning: Consider including the "accessibility.js" module to make your chart more usable for people with disabilities. Set the "accessibility.enabled" option to false to remove this warning. See https://www.highcharts.com/docs/accessibility/accessibility-module.
// import Accessibility from 'highcharts/modules/accessibility'
// Accessibility(HighchartsVue)

let app=createApp(App)
app.use(router)
app.use(store)
app.use(Maska)
app.use(i18n)
app.use(ElementPlus, {locale: en})
app.use(HighchartsVue)
app.component("font-awesome-icon", FontAwesomeIcon)
app.component("Select2", Select2)
app.mount("#app");


