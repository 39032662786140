import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
// lazy-loaded
const Bills = () => import("./components/Bill/Bills.vue")
const Games = () => import("./components/Game/Games.vue")
const Users = () => import("./components/User/Users.vue")
const GamesBill = () => import("./components/GameBill/GamesBill.vue")
const BillManager = () => import("./components/BillManager/BillManager.vue")
const Reports = () => import("./components/Reports/Reports.vue")
const Receipt = () => import("./components/Receipt/ReceiptEdit.vue")
const CreatePartners = () => import("./components/Wizards/CreatePartners.vue")
const GroupOperations = () => import("./components/Wizards/GroupOperations.vue")
const ChangeGame = () => import("./components/Wizards/ChangeGame/ChangeGame.vue")
const ChangeJP = () => import("./components/Wizards/ChangeJP/ChangeJP.vue")
const EventProcessorManual = () => import("./components/Wizards/EventProcessorManual.vue")
const ChangeHardlimit = () => import("./components/Hardlimits/ChangeHardlimit/ChangeHardlimit.vue")
const CurrenciesEdit = () => import("./components/Currency/CurrencyEdit/Currencies.vue")
const LoadersLogotypes = () => import("./components/Loaders/Logotypes/Logotypes")
const Table = () => import("./components/Table/Tables")
const TestTables = () => import("./components/Test/Tables")
const InvisibleBets = () => import("./components/InvisibleBets/InvisibleBets.vue")
const PaymentConfirmation = () => import("./components/Hardlimits/PaymentConfirmation/PaymentConfirmation.vue")
const BetsKoefEditor = () => import("./components/Bets/BetsKoefEditor/BetsKoefEditor.vue")
const TestCharts = () => import("./components/Test/Charts")
const TestHighCharts = () => import("./components/Test/HighCharts")
const SystemLogAnalysis = () => import("./components/Wizards/SystemLogAnalysis.vue")
//const FileUpload = () => import("./components/Loaders/Logotypes/FileUpload")
//const fu = () => import("./components/Loaders/fu/fu")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/bills",
    name: "bills",
    // lazy-loaded
    component: Bills
  },
  {
    path: "/games",
    name: "games",
    // lazy-loaded
    component: Games
  },
  {
    path: "/users",
    name: "users",
    // lazy-loaded
    component: Users
  },
  {
    path: "/gamesbill",
    name: "gamesbill",
    // lazy-loaded
    component: GamesBill
  },
  {
    path: "/billmanager",
    name: "billmanager",
    // lazy-loaded
    component: BillManager
  },
  {
    path: "/reports",
    name: "reports",
    // lazy-loaded
    component: Reports
  },
  {
    path: "/receipt",
    name: "receipt",
    // lazy-loaded
    component: Receipt
  },
  {
    path: "/wizards/createpartners",
    name: "createpartners",
    // lazy-loaded
    component: CreatePartners
  },
  {
    path: "/wizards/groupoperations",
    name: "groupoperations",
    // lazy-loaded
    component: GroupOperations
  },
  {
    path: "/wizards/groupoperations/changegame",
    name: "changegame",
    // lazy-loaded
    component: ChangeGame
  },
  {
    path: "/wizards/groupoperations/changejp",
    name: "changejp",
    // lazy-loaded
    component: ChangeJP
  },
  {
    path: "/wizards/groupoperations/changehardlimit",
    name: "changehardlimit",
    // lazy-loaded
    component: ChangeHardlimit
  },
  {
    path: "/wizards/systemloganalysis",
    name: "SystemLogAnalysis",
    // lazy-loaded
    component: SystemLogAnalysis
  },
  {
    path: "/wizards/eventprocessormanual",
    name: "eventprocessormanual",
    // lazy-loaded
    component: EventProcessorManual
  },
  {
    path: "/currency/currenciesedit",
    name: "currenciesedit",
    component: CurrenciesEdit
  },
  {
    path: "/loaders/logotypes",
    name: "loaderslogotypes",
    component: LoadersLogotypes
  },
  {
    path: "/table/tables",
    name: "table",
    component: Table
  },
  {
    path: "/test/tables",
    name: "test_tables",
    component: TestTables
  },
  {
    path: "/test/charts",
    name: "test_charts",
    component: TestCharts
  },
  {
    path: "/test/highcharts",
    name: "test_highcharts",
    component: TestHighCharts
  },
  {
    path: "/InvisibleBets",
    name: "invisible_bets",
    component: InvisibleBets
  },
  {
    path: "/PaymentConfirmation",
    name: "PaymentConfirmation",
    component: PaymentConfirmation
  },
  {
    path: "/Bets/KoefEditor",
    name: "BetsKoefEditor",
    component: BetsKoefEditor
  },
];

const router = createRouter({
  history: createWebHistory('#'),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = sessionStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    sessionStorage.setItem('user', '{}')
     next('/login')
  } else {
     next();
  }
});

export default router;