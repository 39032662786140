<template>
  <ul class="unstyled " :class="{'ullevel': cur_lv_of_top>=lv_pad || lv_pad==0, 'ullevel0': (cur_lv_of_top<lv_pad && lv_pad>0) || cur_lv=='superbill'}">
    <li clsas="lielem" v-for="(cur, key) in treeData[cur_lv]" :key="key">
        <div class="itemdiv" v-if="cur.hide">
          <div @click="collapse(cur.login)" class="tree-expand"
               :class="ndclass(cur)">
          </div>

          <input class="cpoint" @click="checkAll(cur.login, $event)" type="checkbox" :id="cur.login" :value="cur.login" v-model="store.state.bill.selBills">

          <span @click="checkOne(cur.login, $event)">
            <span v-if="cur.status=='blocked'" class="text-danger item"><i> <i class="fas fa-lock"></i> <b>{{ cur.title }}</b> ({{ cur.login }})</i></span>
            <span v-if="cur.status=='deleted'" class="text-danger item"><s><b>{{ cur.title }}</b> ({{ cur.login }})</s></span>
            <span v-if="cur.status=='active'" class="item" :class="{'text-success': cur.data_type=='demo'}"><b>{{ cur.title }}</b> ({{ cur.login }})</span>
          </span>
        </div>

      <Branches v-if="treeData[cur.login] && is_collapse(cur.login)" :cur_lv="cur.login" :cur_lv_of_top="cur_lv_of_top+1"></Branches>
    </li>
  </ul>
</template>

<script setup>
import {computed, inject, ref, defineProps} from "vue";
import BillService from "../../services/bill.service";
import {useStore} from "vuex";

const store = useStore()

const set_result = inject('set_result')

const treeData = inject('treeData')
const is_filter = inject('is_filter')
const lv_pad = inject('lv_pad')
const firsFind = inject('firsFind')
const setFirsFind = inject('setFirsFind')
const getBillsInfo = inject('getBillsInfo')

const check = inject('check')
const checkOne = inject('checkOne')

const sel = ref(false)
const curReq = ref([])
const tree = ref([])

const collapseBills = inject('collapseBills')
const is_collapse = inject('is_collapse')
const collapse = inject('collapse')

const is_child_show = inject('is_child_show')

const props = defineProps({
  cur_lv: String,
  filter: String,
  cur_lv_of_top: Number
})

const checkAll = inject('checkAll')

const ndclass = inject('ndclass')
</script>
