import axios from 'axios';
import md5 from 'js-md5'

const API_URL = process.env.VUE_APP_APIA+'auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin/?login='+user.username, {
        login: user.username,
        password: md5(user.password)
      })
      .then(response => {
        if (response.data.accessToken) {
          sessionStorage.setItem('user', JSON.stringify(response.data));
        } else {
          sessionStorage.setItem('user', '{}');
        }

        return response.data;
      });
  }

  logout() {
    sessionStorage.removeItem('user');

    return axios.post(API_URL + '/signout/index.php');
  }

  register(user) {
    return axios.post(API_URL + 'signup/', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
