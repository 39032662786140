<template>
  <span class="bltext">{{ $t('bill.billsselectedtree.bills') }}</span>
  <span v-if="list.length==0 && selBills.length==0" class="label not_sel">{{ $t('bill.billsselectedtree.notSelected') }}</span>
  <span v-if="list.length>0 && selBills.length==1">
    <span v-for="(vl, index) in list" :key="vl.login">
      <span v-if="index!=0"> > </span>
      <span @click="path_click(vl.login)" class="label cursor-pointer" :class="{selected: index==list.length-1}">{{vl.title}}</span>
    </span>
  </span>
  <span v-if="list.length>0 && selBills.length>1">
    <span v-for="(vl, index) in list" :key="vl"><span v-if="index!=0">, </span><span class="label selected">{{vl}}</span></span>
    <span v-if="cntBl>0">{{ $t('bill.billsselectedtree.andOther') }}</span>
    <span v-if="cntBl>0" class="label label-info cntbl">{{cntBl}}</span>
  </span>
</template>

<script setup>
import BillService from "../../services/bill.service";
import CurrencieService from "../../services/currencie.service";
import {ref, defineEmits, defineProps, inject, onMounted, computed, watch, watchEffect} from "vue";
import {useStore} from "vuex";

const store = useStore()

const selLogin = ref([])
const list = ref([])
const selBills = inject('SelBills')

function cntBlFn()
{
	return selBills.value.length-2
}
const cntBl = computed(cntBlFn)

const treeData = inject('BillsTreeData')

function getBillsSelectetName(login)
{
  for (let key in treeData.value) {
    let cur = treeData.value[key]
    let keys = Object.keys(cur)

    if(keys.indexOf(login)!=-1) {

      list.value.push(cur[login])
      getBillsSelectetName(key)
    }

   if(keys.indexOf('np'+login)!=-1) {

     list.value.push(cur['np'+login])
     getBillsSelectetName(key)
   }
  }
}

function getBillName(id)
{
	let res=''
	for (let key in treeData.value) {
		let cur = treeData.value[key]
		let keys = Object.keys(cur)

		if(keys.indexOf(id)!=-1) {
			res=cur[id].title
		}

    if(keys.indexOf('np'+id)!=-1) {
      res=cur['np'+id].title
    }
	}

	return res
}

function getBillsSelectetNameList()
{
	list.value.push(getBillName(selBills.value[0]))
	list.value.push(getBillName(selBills.value[1]))
}


function getBillsSelectetNameW(newQuestion, oldQuestion)
{
  selLogin.value=''
  list.value=[]
  if(selBills.value.length==1) {
    getBillsSelectetName(selBills.value[0])
    list.value.reverse()
  }

  if(selBills.value.length>1) {
    getBillsSelectetNameList()
  }
}
watchEffect(getBillsSelectetNameW)

const collapse_bills_list_crum = inject('collapse_bills_list_crum')

function path_click(id)
{
  let bills=[]

  for(let key in list.value)
  {
    let cur = list.value[key]

    bills.push(cur.login)

    if(cur.login==id) {
      collapse_bills_list_crum(id, bills)

      return
    }
  }
}
</script>

<style scoped>
.label {
  display: inline-block;
  padding: 2px 4px;
  font-size: 11.844px;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  border-radius: 3px;
}

.selected {
  background-color: #468847;
}

.not_sel {
  background-color: #b94a48;
}

.cntBl {
	margin-left: 5px;
}

.bltext {
	margin-right: 5px;
}

.label-info {
    background-color: #3a87ad;
}
</style>
