<template>
  <div id="app">
    <div class="row">
      <div class="col">
        <nav v-if="isAuthorized" class="navbar navbar-expand-md navbar-dark bg-dark p-0 pl-2">
          <div class="main_head">
            <div><a class="title" href="#">{{ $t('app.animalitosadmin') }}</a></div>
            <div class="ver">v{{ver}}</div>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false">
            <span class="navbar-toggler-icon"></span>
          </button>
          <button v-if="showTree" :disabled="isBlockTree==true" @click="showHideTree=!showHideTree">
            <i class="fas fa-caret-down"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li v-if="showMain" class="nav-item">
                <router-link to="/" class="nav-link">
                  <font-awesome-icon icon="home" />{{ $t('app.home') }}</router-link>
              </li>
              <li v-if="showBills" class="nav-item">
                <router-link :access="showBills" to="/bills" class="nav-link">{{ $t('app.accounts') }}</router-link>
              </li>
              <li v-if="showUsers" class="nav-item">
                <router-link :access="showUsers" to="/users" class="nav-link">{{ $t('app.users') }}</router-link>
              </li>
              <li v-if="showGamesByBill" class="nav-item">
                <router-link :access="showGamesByBill" to="/gamesbill" class="nav-link">{{ $t('app.games') }}</router-link>
              </li>
              <li v-if="showBillManager" class="nav-item">
                <router-link :access="showBillManager" to="/billmanager" class="nav-link">{{ $t('app.accountManagement') }}</router-link>
              </li>
              <li v-if="showReports" class="nav-item">
                <router-link :access="showReports" to="/reports" class="nav-link">{{ $t('app.reports') }}</router-link>
              </li>
            </ul>
            <div v-if="!currentUser" class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link to="/login" class="nav-link">
                  <font-awesome-icon icon="sign-in-alt" />{{ $t('app.login') }}</router-link>
              </li>
            </div>

            <div v-if="currentUser" class="navbar-nav ml-auto">
              <LanguageSwitcher v-if="is_super"></LanguageSwitcher>
              <li class="nav-item">
                <span class="nav-link">
                  <font-awesome-icon icon="user" />
                  {{ currentUser.username }}
                </span>
              </li>
              <li class="nav-item cursor-pointer">
                <a class="nav-link" @click.prevent="logOut">
                  <font-awesome-icon icon="sign-out-alt" />{{ $t('app.logout') }}
                </a>
              </li>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="row">
      <div :class="{'col-lg-0': !showHideTree, 'col-lg-2': showHideTree}" class="col-sm-12 bg-light" v-show="showHideTree" v-if="isAuthorized && showTree">
        <BillTree></BillTree>
      </div>
      <div :class="{'col-lg-12': !showHideTree, 'col-lg-10': showHideTree}" class="col-sm-12">
        <div class="alert alert-dismissible fade show" :class="[result_code, result_position]" v-if="result_message">
          <p>{{ result_message }}</p>
          <button @click="close_alert" type="button" class="close" :aria-label="$t('app.close')">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <SelectedTree v-if="isAuthorized"></SelectedTree>

        <div class="sidebar">
          <router-view />
        </div>
      </div>
    </div>

    <loading :active="isLoading"
             :can-cancel="false"
             :is-full-page="true">
    </loading>
  </div>
</template>

<script setup>
import {provide, computed, ref, onMounted, watch} from "vue";
import BillTree from "./components/BillsTree/Body.vue";
import SelectedTree from "@/components/BillsTree/SelectedTree.vue";
import {useStore} from "vuex";
import {useRouter} from 'vue-router'
import authService from './services/auth.service';
import LanguageSwitcher from "@/components/Public/LanguageSwitcher/LanguageSwitcher.vue";
import { getCurrentInstance } from 'vue'
import BillService from "./services/bill.service";
import en from 'element-plus/es/locale/lang/en'
import es from 'element-plus/es/locale/lang/es'
import fr from 'element-plus/es/locale/lang/fr'
import ru from 'element-plus/es/locale/lang/ru'
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import XLSX from "xlsx";

const isLoading=ref(false)

function setIsLoading(mode)
{
  isLoading.value=mode
}
provide('setIsLoading', setIsLoading)

function lang_fn()
{
  let lng = en

  switch ($i18n.locale) {
    case 'en':
      lng=en
      break
    case 'es':
      lng=es
      break
    case 'fr':
      lng=fr
      break
    case 'ru':
      lng=ru
      break
  }

  return lng
}
const langEl = computed(lang_fn)
provide('langEl', langEl)

const dataOnlyformatEl = ref('DD.MM.YYYY')
provide('dataOnlyformatEl', dataOnlyformatEl)

const dataformatEl = ref('DD.MM.YYYY HH:mm')
provide('dataformatEl', dataformatEl)

const showHideTree = ref(true)
provide('showHideTree', showHideTree)

const cnttable = ref(0)
provide('cnttable', cnttable)

const runProcess = ref(true)
provide('runProcess', runProcess)

const data_type = ref(null)
provide('data_type', data_type)

const demomess=ref('')
provide('demomess', demomess)

function verifyDemoBill()
{
  demomess.value=''

  let typesData=store.state.bill.typesData

  let lenWork=Object.keys(typesData.work).length
  let lenDemo=Object.keys(typesData.demo).length

  if(lenWork>0 && lenDemo>0) {
    data_type.value='work'

    let demokeys=Object.keys(typesData.demo)

    let dbill1=null
    let dbill2=null

    if(demokeys.length>0) {
      dbill1=demokeys.shift()
      dbill2=demokeys.shift()
    }

    let txt=$t('reports.demo.mess_begin')+': '

    if(dbill1!=undefined) {
      txt=txt+dbill1
    }

    if(dbill2!=undefined) {
      txt=txt+', '+dbill2
    }

    if(demokeys.length>0) {
      let addbill=demokeys.length

      txt=txt+', '+$t('reports.demo.mess_also')+' '+addbill+' '+$t('reports.demo.mess_more')
    }

    txt=txt+'. '+$t('reports.demo.mess_end')+'.'

    demomess.value=txt
  } else  {
    if(lenWork>0) {
      data_type.value = 'work'
    }

    if(lenDemo>0) {
      data_type.value = 'demo'
    }

    demomess.value=''
  }
}
provide('verifyDemoBill', verifyDemoBill)

function calc_data_type(type, bill_id, sel_bill_type)
{
  if(bill_id!=undefined) {
    let id = bill_id.toString()

    if (type == 'add') {
      store.state.bill.typesData[sel_bill_type][id] = id
    }

    if (type == 'del') {
      delete store.state.bill.typesData[sel_bill_type][id]
    }
  }

  if (type == 'clear') {
    store.state.bill.typesData.work = {}
    store.state.bill.typesData.demo = {}
  }
}
provide('calc_data_type', calc_data_type)

const ver = ref(0)
function verifyVer(nver)
{
  if(ver.value>0 && ver.value!=nver) {
    location.reload()
  }

  ver.value=nver
}
provide('verifyVer', verifyVer)

const verData = ref({"bills_ver": 0})
function verifyVerData(nver)
{
  let vdata=JSON.parse(nver.toString())

  if(verData.value.bills_ver>0 && verData.value.bills_ver!=vdata.bills_ver) {
    store.dispatch('bill/updBills')
  }

  verData.value=vdata
}
provide('verifyVerData', verifyVerData)

//cloneStructured
function deepClone(obj) {
  const clObj = {};
  for(const i in obj) {
    if (obj[i] instanceof Object) {
      clObj[i] = deepClone(obj[i]);
      continue;
    }
    clObj[i] = obj[i];
  }
  return clObj;
}
provide('deepClone', deepClone)

function databypath(data, path)
{
  let path_key = path.split('.')

  let ost_arr=data
  for (let key of path_key) {
    ost_arr=ost_arr[key]
  }

  return ost_arr
}

function validatas(data, verdata)
{
  const resval = new Map(); //Массив результатов валидаии
  const arr = new Map();

  for(let key in data) {
    arr.set(key, data[key])
  }

  for (let key of verdata.keys()) {
    let res = true
    let curvaliddata = verdata.get(key)
    let curgetkey = verdata.get(key).at

    let skipfield = false
    if(curgetkey!==undefined) {
      let dtk=curgetkey
      for (let dtkkey of dtk) {
        let curval = databypath(data, dtkkey.field)

        if(curval!=dtkkey.val) {
          skipfield = true
        }
      }
    }

    if(skipfield==true) {
      continue
    }

    if(curvaliddata.dépendance!=undefined) {
      let depres = false
      for (let depkey in curvaliddata.dépendance) {
        let depvalver = curvaliddata.dépendance[depkey]
        let depval = databypath(data, depkey)

        if(typeof depvalver=='object') {
          if(depvalver.includes(depval)==false) {
            depres=true
          }
        } else {
          if(depval!=depvalver) {
            depres=true
          }
        }
      }

      if(depres==true) {
        continue
      }
    }

    let val = databypath(data, key)

    //проверка по регуляркам
    if(curvaliddata.reg!==undefined) {
      let re1 = new RegExp(curvaliddata.reg);
      res = re1.test(val)
    }

    //проверка на минимум
    if(curvaliddata.min!==undefined) {
      let zn = parseFloat(val)
      if(isNaN(zn) || zn<curvaliddata.min) {
        res = false
      }
    }

    //проверка на максимум
    if(curvaliddata.max!==undefined) {
      let zn = parseFloat(val)
      if(isNaN(zn) || zn>curvaliddata.max) {
        res = false
      }
    }

    //сборка массива результатов валидации
    if(res!=true || val==undefined) {
      let mess = $t("app.validate.incorrect_value")

      //получаем сообщение для подмены валидации
      if (curvaliddata.mess !== undefined) {
        mess = curvaliddata.mess
      }
      resval.set(key, mess)
    }
  }

  return resval
}
provide('validatas', validatas)

function prepareDownloadData (data, header)
{
  let ret = [];
  let row_ret = [];

  for(let col of header)
  {
    row_ret[col.field] = col.label;
  }
  ret.push(row_ret);

  for(let row of data)
  {
    row_ret = [];
    for(let col of header)
    {
      row_ret[col.field] = row[col.field];
    }
    ret.push(row_ret);
  }

  return ret;
}

function downloadFileExcel (rows, cols, name, replace_filed=null)
{
  let colsn = []
  for(let key in cols)
  {
    colsn.push(Object.assign({}, cols[key]))
  }

  if (replace_filed != null) {
    for(var key in colsn)
    {
      let kf=colsn[key].field
      if (replace_filed[kf]!=undefined) {
        colsn[key].field=replace_filed[kf]
      }
    }
  }

  let prep_detail_data = prepareDownloadData(rows, colsn)
  const detail_data = XLSX.utils.json_to_sheet(prep_detail_data, {skipHeader: true});
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, detail_data, name);
  XLSX.writeFile(wb,name+'.xlsx');
}
provide('downloadFileExcel', downloadFileExcel)

const internalInstance = getCurrentInstance()
const globalProperties=internalInstance.appContext.config.globalProperties
const $t=globalProperties.$t
provide('$t', $t)
const $i18n=globalProperties.$i18n
provide('$i18n', $i18n)

const isBlockTree = ref(false)
function blockTree()
{
  showHideTree.value=false
  isBlockTree.value=true
}
provide('blockTree', blockTree)

function unblockTree(val)
{
  showHideTree.value=val
  isBlockTree.value=false
}
provide('unblockTree', unblockTree)

//дерево
const collapse_bills = ref(0)
provide('collapse_bills', collapse_bills)

const collapse_bills_list = ref([])
provide('collapse_bills_list', collapse_bills_list)

function collapse_bills_list_crum(bill_id, bills)
{
  collapse_bills_list.value=bills

  collapse_bills.value=bill_id
}
provide('collapse_bills_list_crum', collapse_bills_list_crum)

const BillsTreeData = ref([])
provide('BillsTreeData', BillsTreeData)

function setBillsTreeData(data)
{
  BillsTreeData.value=data
}
provide('setBillsTreeData', setBillsTreeData)

const treeData = ref([])
provide('treeData', treeData)

const getTreeBills = (data) => {
  setBillsTreeData(data)
  treeData.value = data
}
provide('getTreeBills', getTreeBills)
//Конец дерево


const store = useStore()
const router = useRouter()

store.dispatch('bill/clearBills')

const selBillsInfo = ref({})
provide('selBillsInfo', selBillsInfo)
const selBillsInfoAll = ref({})
provide('selBillsInfoAll', selBillsInfoAll)

const tz_list = [
  {"id": "UTC-12", "text": "UTC-12"},
  {"id": "UTC-11", "text": "UTC-11"},
  {"id": "UTC-10", "text": "UTC-10"},
  {"id": "UTC-9:30", "text": "UTC-9:30"},
  {"id": "UTC-9", "text": "UTC-9"},
  {"id": "UTC-8", "text": "UTC-8"},
  {"id": "UTC-7", "text": "UTC-7"},
  {"id": "UTC-6", "text": "UTC-6"},
  {"id": "UTC-5", "text": "UTC-5"},
  {"id": "UTC-4", "text": "UTC-4"},
  {"id": "UTC-3:30", "text": "UTC-3:30"},
  {"id": "UTC-3", "text": "UTC-3"},
  {"id": "UTC-2", "text": "UTC-2"},
  {"id": "UTC-1", "text": "UTC-1"},
  {"id": "UTC+0", "text": "UTC+0"},
  {"id": "UTC+1", "text": "UTC+1"},
  {"id": "UTC+2", "text": "UTC+2"},
  {"id": "UTC+3", "text": "UTC+3"},
  {"id": "UTC+3:30", "text": "UTC+3:30"},
  {"id": "UTC+4", "text": "UTC+4"},
  {"id": "UTC+4:30", "text": "UTC+4:30"},
  {"id": "UTC+5", "text": "UTC+5"},
  {"id": "UTC+5:30", "text": "UTC+5:30"},
  {"id": "UTC+5:45", "text": "UTC+5:45"},
  {"id": "UTC+6", "text": "UTC+6"},
  {"id": "UTC+6:30", "text": "UTC+6:30"},
  {"id": "UTC+7", "text": "UTC+7"},
  {"id": "UTC+8", "text": "UTC+8"},
  {"id": "UTC+8:45", "text": "UTC+8:45"},
  {"id": "UTC+9", "text": "UTC+9"},
  {"id": "UTC+9:30", "text": "UTC+9:30"},
  {"id": "UTC+10", "text": "UTC+10"},
  {"id": "UTC+10:30", "text": "UTC+10:30"},
  {"id": "UTC+11", "text": "UTC+11"},
  {"id": "UTC+12", "text": "UTC+12"},
  {"id": "UTC+12:45", "text": "UTC+12:45"},
  {"id": "UTC+13", "text": "UTC+13"},
  {"id": "UTC+14", "text": "UTC+14"}
];
provide('tz_list', tz_list)

const result_code=ref('')
const result_message=ref('')
const result_position=ref('')

const mask_mnemo={ mask: 'Z*', tokens: { 'Z': { pattern: /[A-Za-z0-9_{} ]|[-]/ }}}
const mask_mnemomin={ mask: 'Z*', tokens: { 'Z': { pattern: /[A-Za-z0-9_ ]|[-]/ }}}
const mask_mnemolg={ mask: 'Z*', tokens: { 'Z': { pattern: /[A-Za-z0-9_{}]|[-]/ }}}
const mask_mnemominlg={ mask: 'Z*', tokens: { 'Z': { pattern: /[A-Za-z0-9_]|[-]/ }}}
const mask_onlydigital={ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}
const mask_bill_name_mnemo={ mask: 'Z*', tokens: { 'Z': { pattern: /[^&.,:;№?%$#@!^~"`/\\]/ }}}
provide('mask_mnemo', mask_mnemo)
provide('mask_mnemomin', mask_mnemomin)
provide('mask_mnemolg', mask_mnemolg)
provide('mask_mnemominlg', mask_mnemominlg)
provide('mask_onlydigital', mask_onlydigital)
provide('mask_bill_name_mnemo', mask_bill_name_mnemo)

const logOut = function ()
{
  store.dispatch('auth/logout');
  store.dispatch('bill/clearBillsInfo');
  router.push('/login');
  defaultLocale()
}
provide('logOut', logOut)

const perPageDropdown = [10, 20, 30, 40, 50, 100, 500, 1000]
provide('perPageDropdown', perPageDropdown)

const set_result = function (code='alert-success', message=$t('app.success'), man_message='')
{
  if(code=='off') {
    result_code.value=''
    result_message.value=''
    result_position.value=''

    return
  }

  result_code.value=code
  if(code!='alert-success') {
    if(code=='401') {
      logOut()
      //man_message='Авторизация истекла' //вариант адекватного сообщения
    }

    result_code.value='alert-danger'
    result_position.value = 'position-fixed fixet-top m-2'
    result_message.value=$t('app.error')
    if(man_message!='') {
      result_message.value=man_message
    }
  } else {
    result_position.value = 'm-2'
    result_message.value=message
  }
}
provide('set_result', set_result)

const close_alert = function ()
{
  set_result('off')
}

let is_role = '';

const cntSelBill = computed(() => store.state.bill.selBills.length)
provide('cntSelBill', cntSelBill)

const selOneBill = computed(() => {
  if(cntSelBill.value==1) {
    return store.state.bill.selBills[0]
  }

  return ''
})
provide('selOneBill', selOneBill)
provide('SelOneBill', selOneBill)

function SelBills_fn()
{
  return store.state.bill.selBills
}
const SelBills = computed(SelBills_fn)
provide('SelBills', SelBills)

const selOneBillInfo = ref({})
provide('selOneBillInfo', selOneBillInfo)

function getBillsInfo()
{
  BillService.getBillsByList(SelBills.value).then(
    (response) => {
      selBillsInfo.value = response.data

      let keys_id = Object.keys(selBillsInfo.value)
      let first_id = keys_id[0]
      selOneBillInfo.value = selBillsInfo.value[first_id]
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}
provide('getBillsInfo', getBillsInfo)

function getBillsInfoAll()
{
  BillService.getBillsAll(false, false).then(
      (response) => {
        selBillsInfoAll.value = response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}
provide('getBillsInfoAll', getBillsInfoAll)

const currentUser = computed(() => store.state.auth.user)
provide('currentUser', currentUser)

function bill_groups_fn()
{
  let groups = []
  if(currentUser.value!=undefined) {
    groups=currentUser.value.bill_groups
  }

  return groups
}
const bill_groups = computed(bill_groups_fn)

const isMainBill = computed (() => selOneBill.value==currentUser.value.bill_id)
provide('isMainBill', isMainBill)

function is_superFn()
{
  let res_acces = false;

  if (currentUser.value!=undefined && currentUser.value['roles']!==undefined && currentUser.value['roles'].includes('super')) {
    res_acces = true
  }

  return res_acces;
}
const is_super = computed(is_superFn)
provide('is_super', is_super)

function is_admin_sbFn()
{
  let res_acces = false;

  if (currentUser.value['roles']!=undefined
      && currentUser.value['roles'].includes('admin')
      && currentUser.value.bill_id==1
  ) {
    res_acces = true
  }

  return res_acces;
}
const is_admin_sb = computed(is_admin_sbFn)
provide('is_admin_sb', is_admin_sb)

const is_bill_sub_add = computed(() => {
  let res_acces = false;

  if (currentUser.value['roles'].includes('super')) {
    res_acces = true
  }

  if (bill_groups.value.includes('bill_sub_add')) {
    res_acces = true
  }

  return res_acces;
})
provide('is_bill_sub_add', is_bill_sub_add)

const is_user_create = computed(() => {
  let res_acces = false;

  if (currentUser.value!==undefined && currentUser.value['roles']!==undefined && currentUser.value['roles'].includes('super')) {
    res_acces = true
  }

  if (bill_groups.value!==undefined &&  bill_groups.value.includes('user_create')) {
    res_acces = true
  }

  return res_acces;
})
provide('is_user_create', is_user_create)

const user_change_pass = computed(() => {
  let res_acces = false;

  if (currentUser.value['roles'].includes('super')) {
    res_acces = true
  }

  if (bill_groups.value.includes('user_change_pass')) {
    res_acces = true
  }

  return res_acces;
})
provide('user_change_pass', user_change_pass)

const user_block = computed(() => {
  let res_acces = false;

  if (currentUser.value['roles'].includes('super')) {
    res_acces = true
  }

  if (bill_groups.value.includes('user_block')) {
    res_acces = true
  }

  return res_acces;
})
provide('user_block', user_block)

const access_groups = computed(() => {
  let ag={}
  for(let key in bill_groups.value) {
    let res_acces = false
    let cur_val = bill_groups.value[key]

    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (bill_groups.value.includes(cur_val)) {
      res_acces = true
    }

    ag[cur_val] = res_acces
  }

  return ag
})
provide('access_groups', access_groups)

provide('is_role', is_role)

function genRandomString(length = 1, charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789")
{
  let res = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    res += charset.charAt(Math.floor(Math.random() * n));
  }

  return res
}
provide('genRandomString', genRandomString)

function genPass() {
  let res = genRandomString(8,"abcdefghkmnpqrstuvwxyz23456789")

  return res
}
provide('genPass', genPass)

function detectLanguage()
{
  const lng = window.navigator.userLanguage || window.navigator.language;
  const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const lang = locales
      .keys()
      .find((key) => lng.includes(key.replace("./", "").replace(".json", "")));

  return lang ? lang.replace("./", "").replace(".json", "") : 'en'
}

function defaultLocale()
{
  $i18n.locale = detectLanguage();
}


function isAuthorized_fn()
{
  let currentSession = JSON.parse(sessionStorage.getItem('user'));
  return currentUser.value !== undefined
      && currentUser.value !== null
      && currentUser.value.accessToken !== undefined
      && currentUser.value.accessToken !== null
      && currentUser.value.accessToken !== ''
      && currentSession !== undefined
      && currentSession !== null
      && currentSession.accessToken !== undefined
      && currentSession.accessToken !== null
      && currentSession.accessToken !== '';

}
const isAuthorized=computed(isAuthorized_fn)
provide('isAuthorized', isAuthorized)

function showTree_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {

    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showTree=computed(showTree_fn)

function showMain_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showMain=computed(showMain_fn)

function showBills_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showBills=computed(showBills_fn)

function showGames_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showGames=computed(showGames_fn)

function showUsers_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showUsers=computed(showUsers_fn)

function showGamesByBill_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showGamesByBill=computed(showGamesByBill_fn)

function showBillManager_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showBillManager=computed(showBillManager_fn)

function showReports_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showReports=computed(showReports_fn)

function showReceipt_fn()
{
  let res_acces = false;
  if (currentUser.value && currentUser.value['roles']) {
    if (currentUser.value['roles'].includes('super')) {
      res_acces = true
    }
  }

  return res_acces;
}
const showReceipt=computed(showReceipt_fn)

function showHideTree_fn()
{
  //тут нужна проверка какая то хитрая не пора ли обновлять дерево
  ////store.dispatch('bill/updBills')
}
watch(showHideTree, showHideTree_fn)

function to_date(datetimechar)
{
  let dtc_split = datetimechar.split(' ')
  let d_split = dtc_split[0].split('.')
  let tm_split = dtc_split[1].split('.')
  let t_split = tm_split[0].split(':')

  let date = d_split[0]
  let month = d_split[1]-1
  let year = d_split[2]
  let hours = t_split[0]
  let minutes = t_split[1]
  let seconds = t_split[2]

  let ms = 0
  if(tm_split[1]!=undefined) {
    ms = tm_split[1]
  }

  return new Date(year, month, date, hours, minutes, seconds, ms)
}
provide('to_date', to_date)

function date_to_format(datetime)
{
  let date_format=''

  date_format=datetime.getDate().toString().padStart(2, '0')+'.'
  date_format+=(datetime.getMonth()+1).toString().padStart(2, '0')+'.'
  date_format+=datetime.getFullYear()+' '
  date_format+=datetime.getHours().toString().padStart(2, '0')+':'
  date_format+=datetime.getMinutes().toString().padStart(2, '0')+':'
  date_format+=datetime.getSeconds().toString().padStart(2, '0')+'.'
  date_format+=datetime.getMilliseconds().toString().padStart(2, '0')

  return date_format
}
provide('date_to_format', date_to_format)

const runRepRemoteData=ref(null)
provide('runRepRemoteData', runRepRemoteData)

function to_check(dt)
{
  runRepRemoteData.value=dt

  router.push('reports')
}
provide('to_check', to_check)

function numberWithSpaces(x) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function formatFLoat(data)
{
  return numberWithSpaces(data.toFixed(2).toString())
}
provide('formatFLoat', formatFLoat)

function numbermore_cf(data, filterString)
{
  var x
  var y

  if(filterString!=null) {
    x = parseInt(filterString.toString().replaceAll(' ', ''))
  }

  if(data!=null) {
    y = parseInt(data.toString().replaceAll(' ', ''))
  }

  return y >= x;
}

provide('numbermore_cf', numbermore_cf)

function sortByInt(x, y) {
  return (parseInt(x) < parseInt(y) ? -1 : (parseInt(x) > parseInt(y) ? 1 : 0));
}
provide('sortByInt', sortByInt)

function mounted_fn()
{
  store.dispatch('bill/clearBills')

  if(currentUser.value!=null && Object.keys(currentUser.value).length>0) {
    getBillsInfo()
    getBillsInfoAll()
  }
}
onMounted(mounted_fn)
</script>

<style scoped>
html, body
{
  height: 100%;
  padding: 0;
}

.sidebar {
  height: 93vh;
  overflow-y: auto;
}

.router-link-active {
  border: #999999 solid 2px;
}

.alert
{
  z-index: 1000;
}

.container-fluid {
  overflow-x:hidden;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.main_head {
  padding: 0 10px;
  margin: 0;
}

.title {
  color: white;
  font-size: 12pt;
  text-decoration: none;
}

.ver {
  color: white;
  font-size: 8pt;
}
</style>

<style>
.width100p {
  width: 100%;
}

.link {
  cursor: pointer;
  color: blue;
}

.linkb {
  cursor: pointer;
  color: blue;
  font-weight: bold;
}

.bgcolor_up {
  background-color: #dff0d8;
}

.bgcolor_down {
  background-color: #f2dede;
}

.bgcolor_gray1 {
  background-color: #dddddd;
}

.bgcolor_gray2 {
  background-color: #cccccc;
}

.font_bold {
  font-weight: bold;
}

.el-table__header th {
  background: linear-gradient(#F4F5F8, #F1F3F6) !important;
  border: 1px solid #DCDFE6;
}

.el-table__header th, .el-table__body td {
  padding: 1px 5px !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 10pt;
  color: #606266;
}

.el-table__header th .cell, .el-table__body td .cell {
  padding: 1px 1px !important;
  font-size: 10pt !important;;
  word-break: break-word !important;
}

table.vgt-table td, table.vgt-table th {
  padding: 1px 5px !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 10pt;
  color: black;
}

table.nowrap td {
  white-space: nowrap;
}

.icon-size {
  font-size: 0.5rem;
  padding: 0;
}

.input_err {
  border: 1px solid red;
}

.input_err_text {
  color: red;
}

.cursor-pointer
{
  cursor: pointer;
}

.validerr
{
  padding: 5px;
  color: red;
}

.highcharts-credits
{
  display: none;
}

.align_right, .align_right .cell{
  text-align: right;
}

.row_color_even_odd tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.row_color_even_odd tr:nth-child(even) {
  background-color: #e0e0e0;
}

.row_color_hover tr:hover {
  background-color: #c7ced8;
}

</style>

