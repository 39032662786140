import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'currencie/';


class CurrencyService {
  getCurrenciesList() {
    return axios.post(API_URLA + 'getCurrenciesList/');
  }
  getCurrencies() {
    return axios.post(API_URLA + 'getCurrencies/');
  }
  getCurrency(iso) {
    return axios.post(API_URLA + 'getCurrency/', {"iso": iso});
  }
  getExchangeRates(iso) {
    return axios.post(API_URLA + 'getExchangeRates/', {"iso": iso});
  }
  saveCurrency(data) {
    return axios.post(API_URLA + 'saveCurrency/', data);
  }
}

export default new CurrencyService();
